a {
  text-decoration: none;
}

h1 {
  color: #35a6c0;
  font-family: Lobster, cursive;
  font-size: 80px;
}

.active-writer {
  color: #7bcbbc;
  justify-content: center;
  align-items: center;
  font-family: Dosis, sans-serif;
  font-size: 30px;
  font-style: italic;
  display: flex;
}

.card {
  height: 100%;
  cursor: pointer;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
}

.card img, iframe {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card p {
  color: #35a6c0;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-family: Dosis, cursive;
  font-size: 25px;
  display: flex;
}

.more-btn {
  color: #fff;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background-color: #35a6c0;
  border-color: #fff;
  border-radius: 20px;
  padding: 20px;
  font-family: Dosis, sans-serif;
  font-size: 50px;
  display: block;
}

.more-btn:hover {
  color: #fff;
  background-color: #7bcbbc;
}

.more-btn.white-hover:hover {
  color: #7bcbbc;
  background-color: #fff;
}

.blogs-container, .video-container, .podcast-container, .open-source-container {
  flex-direction: column;
  gap: 40px;
  padding: 50px 25px;
  display: flex;
}

.medium-logo, .podcast-angular-logo, .os-github-logo, .os-npm-logo, .blog-rxjs-logo, .video-js-logo, .video-youtube-logo, .in-depth-logo {
  display: none;
}

.video-container, .open-source-container {
  background: #7bcbbc;
}

.video-container h1, .open-source-container h1 {
  color: #fff;
}

@media (min-width: 1500px) {
  .blogs-container {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(12, auto-fit);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    margin-top: 100px;
    margin-left: 200px;
    margin-right: 200px;
    display: grid;
  }

  .blog-posts-title {
    grid-column: 1 / 3;
  }

  .ultimate-library-guide {
    grid-area: 1 / 4 / 3 / 7;
  }

  .ultimate-library-guide img {
    height: 300px;
  }

  .advanced-typescript {
    grid-area: 2 / 1 / 4 / 3;
  }

  .advanced-typescript img {
    height: 300px;
  }

  .blog-rxjs-logo {
    grid-area: 2 / 3 / 3 / 4;
    transform: rotate(20deg);
  }

  .angular-standalone {
    grid-area: 3 / 3 / 5 / 6;
  }

  .angular-standalone img {
    height: 350px;
  }

  .customizable-components {
    grid-area: 6 / 1 / 9 / 4;
  }

  .customizable-components img {
    height: 400px;
  }

  .split-libraries {
    grid-area: 6 / 4 / 9 / 8;
  }

  .split-libraries img {
    height: 400px;
  }

  .medium-logo {
    height: 50px;
    grid-area: 4 / 1 / 6 / 3;
    justify-content: center;
    display: flex;
    transform: rotate(-25deg);
  }

  .active-writer {
    grid-area: 5 / 1 / 6 / 9;
  }

  .medium-stats {
    cursor: auto;
    grid-area: 9 / 1 / 11 / 3;
  }

  .medium-stats img {
    height: 350px;
  }

  .lazy-load {
    grid-area: 9 / 3 / 12 / 8;
  }

  .lazy-load img {
    height: 450px;
  }

  .in-depth-logo {
    grid-area: 11 / 1 / 13 / 3;
  }

  .in-depth-logo img {
    width: 100%;
    border-radius: 20px;
  }

  .blog-explore-more {
    color: #35a6c0;
    grid-area: 13 / 1 / 14 / 4;
    font-family: Dosis, sans-serif;
    font-size: 25px;
  }

  .video-container {
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    grid-template-rows: repeat(8, 200px);
    grid-template-columns: repeat(6, 1fr);
    margin-left: 0;
    margin-right: 0;
    padding: 100px 200px;
    display: grid;
  }

  .video-title {
    color: #fff;
    grid-column: 1 / 3;
  }

  .video-js-logo {
    grid-area: 2 / 1 / 3 / 2;
    justify-content: center;
    display: flex;
    transform: rotate(-10deg);
  }

  .video-nest {
    grid-area: 1 / 3 / 3 / 6;
  }

  .kevins-angular-tips {
    grid-area: 1 / 6 / 3 / 8;
    transform: rotate(20deg);
  }

  .video-lazy-load-standalone-components {
    grid-area: 3 / 1 / 5 / 3;
  }

  .video-marble-testing {
    grid-area: 3 / 3 / 5 / 5;
  }

  .video-object-destructuring {
    grid-area: 3 / 5 / 5 / 7;
  }

  .video-kevins-tech-tutorials {
    grid-area: 5 / 1 / 7 / 4;
    display: flex;
    transform: rotate(-5deg);
  }

  .video-harness-testing {
    grid-area: 5 / 4 / 7 / 8;
  }

  .lets-talk-open-source {
    grid-area: 7 / 1 / 9 / 5;
  }

  .video-youtube-logo {
    grid-area: 7 / 5 / 9 / 7;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: rotate(20deg);
  }

  .video-explore-more {
    grid-area: 9 / 1 / 10 / 5;
  }

  .podcast-container {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, auto-fit);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    padding: 100px 200px;
    display: grid;
  }

  .podcast-title {
    grid-column: 1 / 3;
  }

  .podcast-angular-air {
    grid-area: 1 / 3 / 3 / 7;
  }

  .podcast-angular-air iframe {
    height: 70%;
  }

  .podcast-adventures-in-angular {
    grid-area: 3 / 1 / 5 / 4;
  }

  .podcast-my-javascript-story {
    grid-area: 3 / 4 / 5 / 8;
  }

  .podcast-angular-logo {
    grid-area: 2 / 1 / 3 / 3;
    justify-content: center;
    display: flex;
    transform: rotate(-20deg);
  }

  .open-source-container {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(10, auto-fill);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    margin-left: 0;
    margin-right: 0;
    padding: 100px 200px;
    display: grid;
  }

  .open-source-title {
    color: #fff;
    grid-column: 1 / 3;
  }

  .os-github-logo {
    grid-area: 1 / 5 / 2 / 7;
    justify-content: center;
    display: flex;
    transform: rotate(20deg);
  }

  .os-svg-to-ts {
    grid-area: 2 / 1 / 3 / 3;
  }

  .os-svg-to-ts img {
    height: 50%;
    object-fit: contain;
  }

  .os-npm-logo {
    grid-area: 4 / 5 / 6 / 7;
    justify-content: center;
    align-items: center;
    display: flex;
    transform: rotate(20deg);
  }

  .os-more {
    grid-area: 6 / 5 / 7 / 8;
  }

  .os-ng-sortgrid {
    grid-area: 2 / 5 / 4 / 7;
  }

  .os-ng-sortgrid img {
    height: 70%;
    object-fit: contain;
  }

  .os-phl {
    grid-area: 2 / 3 / 3 / 5;
  }

  .os-phl img {
    height: 50%;
    object-fit: contain;
  }

  .os-rjp {
    grid-area: 1 / 3 / 2 / 5;
  }

  .os-rjp img {
    height: 300px;
  }

  .os-ng-samurai {
    grid-area: 3 / 1 / 7 / 5;
  }
}

/*# sourceMappingURL=index.1a6fdfb2.css.map */
