a {
  text-decoration: none;
}

h1 {
  font-size: 80px;
  font-family: 'Lobster', cursive;
  color: #35a6c0;
}

.active-writer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Dosis', sans-serif;
  color: #7bcbbc;
  font-size: 30px;
  font-style: italic;
}

.card {
  height: 100%;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;
  cursor: pointer;
}

.card img,
iframe {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.card p {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 25px;
  font-family: 'Dosis', cursive;
  color: #35a6c0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-btn {
  display: block;
  background-color: #35a6c0;
  color: white;
  border-radius: 20px;
  padding: 20px;
  font-family: 'Dosis', sans-serif;
  font-size: 50px;
  width: 100%;
  text-align: center;
  border-color: white;
  cursor: pointer;
}

.more-btn:hover {
  background-color: #7bcbbc;
  color: white;
}

.more-btn.white-hover:hover {
  color: #7bcbbc;
  background-color: white;
}

.blogs-container,
.video-container,
.podcast-container,
.open-source-container {
  display: flex;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 25px;
  padding-right: 25px;
  gap: 40px;
}

.medium-logo,
.podcast-angular-logo,
.os-github-logo,
.os-npm-logo,
.blog-rxjs-logo,
.video-js-logo,
.video-youtube-logo,
.in-depth-logo {
  display: none;
}

.video-container,
.open-source-container {
  background: #7bcbbc;
}
.video-container h1,
.open-source-container h1 {
  color: white;
}

@media (min-width: 1500px) {
  /* BLOGS CONTAINER */
  .blogs-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(12, auto-fit);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    margin-top: 100px;
    margin-left: 200px;
    margin-right: 200px;
  }
  /* Grid alignment */
  .blog-posts-title {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .ultimate-library-guide {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .ultimate-library-guide img {
    height: 300px;
  }

  .advanced-typescript {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 4;
  }

  .advanced-typescript img {
    height: 300px;
  }

  .blog-rxjs-logo {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
    transform: rotate(20deg);
  }

  .angular-standalone {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 5;
  }

  .angular-standalone img {
    height: 350px;
  }

  .customizable-components {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 6;
    grid-row-end: 9;
  }

  .customizable-components img {
    height: 400px;
  }

  .split-libraries {
    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 6;
    grid-row-end: 9;
  }

  .split-libraries img {
    height: 400px;
  }

  .medium-logo {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 6;
    height: 50px;

    display: flex;
    justify-content: center;
    transform: rotate(-25deg);
  }

  .active-writer {
    grid-column-start: 1;
    grid-column-end: 9;
    grid-row-start: 5;
    grid-row-end: 6;
  }

  .medium-stats {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 9;
    grid-row-end: 11;
    cursor: auto;
  }

  .medium-stats img {
    height: 350px;
  }

  .lazy-load {
    grid-column-start: 3;
    grid-column-end: 8;
    grid-row-start: 9;
    grid-row-end: 12;
  }

  .lazy-load img {
    height: 450px;
  }

  .in-depth-logo {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 11;
    grid-row-end: 13;
  }

  .in-depth-logo img {
    border-radius: 20px;
    width: 100%;
  }

  .blog-explore-more {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 13;
    grid-row-end: 14;
    font-family: 'Dosis', sans-serif;
    font-size: 25px;
    color: #35a6c0;
  }

  /* VIDEO CONTAINER */
  .video-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(8, 200px);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 200px;
    padding-right: 200px;
    margin-left: 0;
    margin-right: 0;
  }

  .video-title {
    grid-column-start: 1;
    grid-column-end: 3;
    color: white;
  }

  .video-js-logo {
    display: flex;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    transform: rotate(-10deg);
  }

  .video-nest {
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .kevins-angular-tips {
    grid-column-start: 6;
    grid-column-end: 8;
    grid-row-start: 1;
    grid-row-end: 3;
    transform: rotate(20deg);
  }

  .video-lazy-load-standalone-components {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 5;
  }

  .video-marble-testing {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 5;
  }

  .video-object-destructuring {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 3;
    grid-row-end: 5;
  }

  .video-kevins-tech-tutorials {
    display: flex;

    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 5;
    grid-row-end: 7;
    transform: rotate(-5deg);
  }

  .video-harness-testing {
    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 5;
    grid-row-end: 7;
  }

  .lets-talk-open-source {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 7;
    grid-row-end: 9;
  }

  .video-youtube-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 7;
    grid-row-end: 9;
    transform: rotate(20deg);
  }

  .video-explore-more {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 9;
    grid-row-end: 10;
  }

  /* Podcast CONTAINER */
  .podcast-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, auto-fit);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 200px;
    padding-right: 200px;
  }

  .podcast-title {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .podcast-angular-air {
    grid-column-start: 3;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .podcast-angular-air iframe {
    height: 70%;
  }

  .podcast-adventures-in-angular {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 5;
  }

  .podcast-my-javascript-story {
    grid-column-start: 4;
    grid-column-end: 8;
    grid-row-start: 3;
    grid-row-end: 5;
  }

  .podcast-angular-logo {
    display: flex;
    justify-content: center;
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    transform: rotate(-20deg);
  }

  /* OPEN SOURCE CONTAINER */
  .open-source-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(10, auto-fill);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 200px;
    padding-right: 200px;
    margin-left: 0;
    margin-right: 0;
  }

  .open-source-title {
    grid-column-start: 1;
    grid-column-end: 3;
    color: white;
  }

  .os-github-logo {
    display: flex;
    justify-content: center;
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
    transform: rotate(20deg);
  }

  .os-svg-to-ts {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .os-svg-to-ts img {
    height: 50%;
    object-fit: contain;
  }

  .os-npm-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 4;
    grid-row-end: 6;
    transform: rotate(20deg);
  }

  .os-more {
    grid-column-start: 5;
    grid-column-end: 8;
    grid-row-start: 6;
    grid-row-end: 7;
  }

  .os-ng-sortgrid {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 4;
  }

  .os-ng-sortgrid img {
    height: 70%;
    object-fit: contain;
  }

  .os-phl {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .os-phl img {
    height: 50%;
    object-fit: contain;
  }

  .os-rjp {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .os-rjp img {
    height: 300px;
  }

  .os-ng-samurai {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 7;
  }
}
